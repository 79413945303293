import axios from "axios";

import endpoint from '../../endpoint.js';

const state = {
  token: localStorage.getItem("user-token") || "",
  currentUser: JSON.parse(localStorage.getItem("current-user")) || null,
  status: "",
  hasLoadedOnce: false
};

const mutations = {
  authRequest: state => {
    state.status = "authRequesting";
  },
  authSuccess: (state, user) => {
    state.status = "auth success";
    state.token = user.accessToken;
    state.hasLoadedOnce = true;

    let currentUser = {
      id: user.id,
      username: user.username,
      role: user.role,
      firstname: user.firstname,
      lastname: user.lastname
    };
    state.currentUser = currentUser;
    localStorage.setItem("current-user", JSON.stringify(currentUser));
    localStorage.setItem("user-token", user.accessToken);
  },
  authError: state => {
    state.status = 403;
    state.hasLoadedOnce = true;
    localStorage.removeItem("user-token");
    localStorage.removeItem("current-user");
    localStorage.removeItem("powerBI-token");
  },
  authLogout: state => {
    state.status = "";
    state.token = "";
    state.currentUser = "";
    localStorage.removeItem("user-token");
    localStorage.removeItem("current-user");
    localStorage.removeItem("powerBI-token");
  }
};

const actions = {
  retrieveToken (context, credentials ) {
    // console.log("retrieveToken calling")
    return new Promise((resolve, reject) =>  {
      context.commit('authRequest');
    
      axios.post(endpoint + '/api/login', {
        username : credentials.username, 
        password: credentials.password
      })
      .then(response => {
        // use alert to debug here
        if(response.data === false){
          return response.status(404).send('No user found.');
        }else{
          context.commit('authSuccess', response.data);
          resolve(response)
        }

      })
      .catch(error => {
        context.commit('authError', error);
        // console.log(error.response.data);
        resolve(error.response);
        // reject(error)
      })
    });
  },
  authLogout: (context) => {
    return new Promise((resolve, reject) =>  {
      context.commit('authLogout');
      if(state.token == "") {
        resolve()
      }
      else {
        reject()
      }
    })
  }
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  currentUser: state => state.currentUser
};

const authModule = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
export default authModule;