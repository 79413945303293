import axios from 'axios';
import authHeader from '../../../services/authHeader';

import router from '../../../router';

import endpoint from '../../endpoint.js';

// Note: why is this needed?
// axios.interceptors.response.use(
// 	res => res,
// 	err => {
//     console.log("axios.interceptors.response.use(")
//     console.log(err.message)
// 		router.push({name: 'logout', params: {error: err.response.data.message } })
// 	}
//   )

const state = {
	token: JSON.parse(localStorage.getItem("powerBI-token")) || "",
	workspaces: [],
	reports: [],
	viewMode: "view",
	activeElement: null,
	firstRun: true,
	linkOptions: null,
	linkTable: null,
	linkColumn: null,
  rendering: true,
	saveState: JSON.parse(localStorage.getItem("saveState")) || "",
}

const mutations = {
	UPDATE_TOKEN (state, payload) {
    // console.log("Inside UPDATE_TOKEN, check payload:")
    // console.log(payload)
		state.token = payload;
		localStorage.setItem("powerBI-token", JSON.stringify(payload));
	},
	UPDATE_WORKSPACES (state, payload) {
		localStorage.removeItem("powerBI-token");  // remove stored token after each change
		state.workspaces = payload;
	},
	UPDATE_REPORTS (state, payload) {
		localStorage.removeItem("powerBI-token");  // remove stored token after each change
		state.reports = payload;
    // console.log("inside UPDATE_REPORTS")
	},
	UPDATE_VIEWMODE (state) {
		if(state.viewMode == 'view') {
			state.viewMode = 'edit';
		}
		else {
			state.viewMode = 'view';
		}
		// console.log("changed to " + state.viewMode);
	},
	UPDATE_ACTIVE_ELEMENT (state, payload) {
		state.activeElement = payload;
		// console.log("in store; active element")
	},
	UPDATE_LINKOPTIONS (state, payload) {
		state.linkOptions = payload;
	},
	UPDATE_LINKTABLE (state, payload) {
		state.linkTable = payload;
		// console.log('table stored');
	},
	UPDATE_LINKCOLUMN (state, payload) {
		state.linkColumn = payload;
	},
	UPDATE_SAVESTATE (state, payload) {
		if(payload) {
			localStorage.setItem("saveState", JSON.stringify(payload));
			state.saveState = payload;
		}
		else {
			let saveState = {
				'workspace' : state.activeWorkspace,
				'report'    : state.currentReport,
				'linkTable' : state.linkTable,
				'linkColumn': state.linkColumn,
        'rendering' : state.rendering,
			};
			localStorage.setItem("saveState", JSON.stringify(saveState));
			state.saveState = saveState;
		}
	},
	RESET_SAVESTATE (state) {
		state.saveState = "";
		state.token = "";
		localStorage.removeItem("saveState");
		localStorage.removeItem("powerBI-token");
		// console.log("reset state");
	},
  RENDERING_SAVESTATE (state){
    state.rendering = false;
    // console.log("RENDERING_SAVESTATE done");
  },
}

const actions = {
	// ToDo: get user profile
	getWorkspaces (context, userId ) {
		axios.get(endpoint + '/api/v1/user/' + userId + '/workspaces',{
			headers: authHeader() 
		}).then((response) => {
      // console.log("getWorkspaces")
      // console.log(response)
		  context.commit('UPDATE_WORKSPACES', response.data)
		}).catch(error => {
			console.log(error);
		});
	},
	getReports (context, workspaceId ) {
    // console.log("in getReports() in Store and requires worskpaceId (int) + token!")
    // console.log(authHeader)
    // console.log(workspaceId)
		axios.get(endpoint + '/api/v1/workspaces/' + workspaceId + '/reports',{
			headers: authHeader() 
		}).then((response) => {
      // console.log("response");
      // console.log(response.data);
			context.commit('UPDATE_REPORTS', response.data)
		}).catch(error => {
			console.log(error);
		});
	},
	getReportToken (context, id ) {
    // console.log('getReportToken')
		// console.log(id);
    state.rendering = true;
		axios.get(endpoint + '/api/v1/token/' + id.workspaceId + '/' + id.reportId ,{
			headers: authHeader() 
		}).then((response) => {
      // console.log(response.data)
      // console.log(response.data.status)
		  context.commit('UPDATE_TOKEN', response.data)
		}).catch(error => {
			console.log(error.response);
      state.rendering = false;
		});
	},
	setViewMode (context) {
		context.commit('UPDATE_VIEWMODE');
	},
	setActiveElement (context, element ) {
		context.commit('UPDATE_ACTIVE_ELEMENT', element)
	},
	setLinkOptions (context, options ) {
		context.commit('UPDATE_LINKOPTIONS', options)
	},
	setLinkTable (context, table) {
		context.commit('UPDATE_LINKTABLE', table);
	},
	setLinkColumn (context, column) {
		context.commit('UPDATE_LINKCOLUMN', column);
	},
	setSaveState (context, saveState = null, userId) {
		context.commit('UPDATE_SAVESTATE', saveState);

		// grab savestate and store to API
		const data = {"stateString": JSON.stringify(state.saveState)};

		axios.post(endpoint + '/api/v1/user/' + userId + '/state' , data, {
			headers: authHeader()
		}).then((response) => {
			// console.log(response);
		}).catch(error => {
			console.log(error);
		});
	},
	resetSaveState (context) {
    // console.log("resetSaveState triggered!")
		context.commit('RESET_SAVESTATE');
	},
	retrieveSaveState (context, userId ) {
    // console.log("retrieveSaveState triggered")
		axios.get(endpoint + '/api/v1/user/' + userId + '/state' ,{
			headers: authHeader() 
		}).then((response) => {
			// ToDo: check for enpty
			const savedState = JSON.parse(response.data.state);
			context.commit('UPDATE_SAVESTATE', savedState);
			context.commit('UPDATE_LINKTABLE', savedState.linkTable);
			context.commit('UPDATE_LINKCOLUMN', savedState.linkColumn);
			context.commit('UPDATE_ACTIVE_WORSPACE', savedState.workspace);
			context.commit('UPDATE_CURRENT_REPORT', savedState.report);
		}).catch(error => {
			console.log(error);
		});
	},
  setRendering (context){
    // console.log("setRendering triggered!")
    // console.log(context)
		context.commit('RENDERING_SAVESTATE');
  },
}

const getters = {
	tokenObject: state => state.token,
	workspaces: state => state.workspaces,
	reports: state => state.reports,
	viewMode: state => state.viewMode,
	activeElement: state => state.activeElement,
	firstRun: state => state.firstRun,
	linkOptions: state => state.linkOptions,
	linkTable: state => state.linkTable,
	linkColumn: state => state.linkColumn,
	saveState: state => state.saveState,
  rendering: state => state.rendering,

};

const powerBIModule = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
export default powerBIModule;