<template>
  <v-app>
    <span class="bg" v-if="isAuthenticated==false"></span>
    <span class="bggrey" v-else></span>
    <v-app-bar v-if="isAuthenticated"
      app 
      clipped-left
      color="primary"
      dark
      dense
    >
    <v-avatar :tile="true">
      <v-img
        src="/logo/keygene_logo.png"
        max-height="70"
        max-width="70"
        contain
        alt="KEYGENE"
      ></v-img>
      </v-avatar>
      <span>{{'\xa0'}}{{'\xa0'}}{{'\xa0'}}{{'\xa0'}}</span>
      <v-toolbar-title class="subtitle-2">Project portal</v-toolbar-title>
      <v-spacer></v-spacer>
    
      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
          <v-avatar 
            size="35"
            class="v-avatar-user"
            >
            <span class="white--text text-body-2">{{setAvatarName(currentUser)}}</span>
          </v-avatar>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list v-if="isAuthenticated">
          <v-list-item link to="/admin"  v-if="currentUser.role == 'admin'">
            <v-list-item-title>Admin Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/logout" > 
            <v-list-item-title>Sign out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>


 <!-- start navigation-drawer -->
 <v-navigation-drawer v-if="isAuthenticated"
      clipped
      left 
      app
      permanent
      expand-on-hover
      absolute 
      mini-variant
      height="100%"
    >
    <v-list
      dense
      expand
      nav
    >
      <!-- ENTIRE itemlist is wrapped in a template -->
      <template v-for="item in navLinkItems">
        <!-- use v-if to determine if 2nd level nesting is needed -->
        <!-- if it's a menu item with no children -->
        <v-list-item
          v-if="!item.children"
          :key="item.id"
          :to="item.to"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        <!-- else if it has children aka projects-->
        <!-- remove toggle dropdown marker-->
        <v-list-group
          v-else
          :group="item.to"
          :append-icon="marker = ''" 
          :key="item.id"
        >
          <!-- this template is for the title of top-level items with children -->
          <!-- disable click toggle for Project Item-->
          <template v-slot:activator>
            <v-list-item
              active-class="deep-purple--text text--accent-4"
              :to="item.to"
              class="item-link"
              @click.stop="doNothing()"
            >
              <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </template>

          <!-- this template is for the children/projects (2nd level) -->
                
            <!-- if there is a 3rd level -->
            <!-- another v-if to determine if there's a 3rd level -->
            <!-- remove toggle dropdown marker-->
               <!-- disable click toggle for report Item == 0-->
            <template v-for="project in item.children">
            <v-list-group 
              v-if="!project.subchildren"
              color="pink"
              :prepend-icon="marker = '  '" 
              :group="project.to"
              sub-group
              :key="project.id"
           
            >
              <template v-slot:activator 
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <router-link class="text-decoration-none" :to="project.to" >{{ project.text }}</router-link>
                  </v-list-item-title>
                </v-list-item-content>
   
              </template>
            </v-list-group>
            <v-list-group 
              v-else
              color="primary"
              :group="project.to"
              sub-group
              :key="project.id"
            >
              <template v-slot:activator
              >
                <v-list-item-content @click.stop="doNothing()">
                  <v-list-item-title>
                   
                  <router-link class="text-decoration-none" :to="project.to" >{{ project.text }}</router-link>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template >
                <v-list-item v-for="(report, k) in project.subchildren"
                  :key="k"
                  color="primary"
                  :value="true"
                  :href="report.to"
                  class="v-list-item-report"
                >
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="v-item-report-span">{{ report.text }}</span>
                  </v-list-item-title>
                </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>
            </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
     <!-- end -->
    <div style="padding: 48px 0px 60px 56px;"> 
    <v-main class="main-app">
    <v-container fluid  style="height: 100%;" >
    <router-view></router-view>
    </v-container>
    </v-main>
    </div>

    <v-footer
      app
      absolute
  
      color="primary"
      light
      v-if="isAuthenticated"
    >
      <v-col
        class="text-center white--text"
        cols="12"
      >
        2022-{{ new Date().getFullYear() }} — <strong><a style="color: inherit;text-decoration: none;" href="https://www.keygene.com/"> KeyGene</a></strong>
      </v-col>
    </v-footer>

  </v-app>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'App',
  data: () => ({
    navLinkItems: [
    ],
  }),
  components: {
  },
  computed: {
    ...mapGetters('auth',[
      'isAuthenticated',
      'currentUser'
    ]),
    ...mapGetters('projects',[
      'projects',
      'navLinks'
    ]),
  },
  mounted() {

  },

  updated() {
  //  console.log(this.$route);

  },
  watch: {
    beforeRouteLeave: function(to, from, next) { 
        // console.log("In beforeRouteLeave of AnotherComponent")
        next();
    },
    navLinks() {
      this.getNavLinks(this.navLinks);
    },
  },
  created() {
    if(this.isAuthenticated){
      // console.log(this.currentUser)
      this.$store.dispatch('projects/retrieveAllProjectsForNavLinks', this.currentUser.id);
    }
  },
  methods: {
    doNothing(){return null;},
    getNavLinks(rawData){
      var resultArr = [];
      var projectArr = []; 
      var reportArr = [];

      // populate data for report links - subchildren 
      for (const [pj_obj_idx, pj_obj] of Object.entries(rawData)) {

        //push report id and name to hasharray 
        if (reportArr[pj_obj.project_name]) { 
            reportArr[pj_obj.project_name].push({report_id: pj_obj.report_id, workspace_id: pj_obj.workspace_id, report_name:pj_obj.description, text:pj_obj.description, to: '/projects/'+pj_obj.workspace_id+'/reports/'+pj_obj.report_id+''})
          } else {
            reportArr[pj_obj.project_name] = [{report_id: pj_obj.report_id, workspace_id: pj_obj.workspace_id, report_name:pj_obj.description, text:pj_obj.description, to: '/projects/'+pj_obj.workspace_id+'/reports/'+pj_obj.report_id+''}]
          }
      }

      let projectCounter= 1;
      // populate data for project links and attach the subchildren (reports)
      for (const [pj_obj_idx, pj_obj] of Object.entries(rawData)) {

          //push report id and name to hasharray 
          if (projectArr[pj_obj.project_name]) { 
            let x = null; // project_name already exits ignore this step
          } else {
           
            projectArr[pj_obj.project_name] = []
            projectArr[pj_obj.project_name].push({ text: pj_obj.project_name, workspace_id: pj_obj.workspace_id, id: projectCounter, to: '/projects/'+pj_obj.workspace_id+'', subchildren: reportArr[pj_obj.project_name]});
            projectCounter++;
          }
      }

      // projectArr = [
      //   {
      //   text : 'Proj #1234',
      //   to : '/projects/1234',
      //   id : 1,
      //   subchildren: [
      //     {text: 'Candidate genes', to : '/projects/1234/reports/candidate_genes', id : 1},
      //     {text: 'Breeding values', to : '/projects/1234/reports/breeding_values', id : 2},
      //     {text: 'Yield estimate', to : '/projects/1234/reports/yield_estimate', id : 3},
      //   ],
      // },
      // {
      //   text : 'Proj #32323',
      //   to : '/projects/32323',
      //   id : 1,
      //   subchildren: [
      //     reportArr['Project #10001'][1]
      //   ],
      // },
      // ]
      

      let projectArrayNew = [];
      for (const [pj_obj_idx, pj_obj] of Object.entries(projectArr)) {
        projectArrayNew.push(pj_obj[0]);
      }

      // create template with Overview and Project link, attach children array to project nav link
      resultArr = [
        {
          to     : '/',
          icon   : 'mdi-home-outline',
          text   : 'Overview',
          id     : 1,
        },
        {
          to     : '/projects',
          icon   : 'mdi-folder-text-outline',
          text   : 'Projects',
          id     : 2,
          children: projectArrayNew
        },
      ]


      // let templateDummyArr = 
      // [
      //   {
      //     to     : '/',
      //     icon   : 'mdi-home-outline',
      //     text   : 'Overview',
      //     id     : 1,
      //   },
      //   {
      //     to      : '/projects',
      //     icon    : 'mdi-folder-text-outline',
      //     text    : 'Projects',
      //     id      : 2,
      //     children: [
      //     {
      //       text : 'Proj #1234',
      //       to    : '/projects/1234',
      //       id    : 1,
      //       subchildren: [
      //         {text: 'Candidate genes', to : '/projects/1234/reports/candidate_genes', id : 1},
      //         {text: 'Breeding values', to : '/projects/1234/reports/breeding_values', id : 2},
      //         {text: 'Yield estimate', to : '/projects/1234/reports/yield_estimate', id : 3},
      //       ],
      //   },
      //   {
      //     text : 'Proj #3212',
      //     to    : '/projects/3212',
      //     id    : 2,
      //     subchildren: [
      //       {text: 'Yield estimate', to : '/projects/3212/reports/yield_estimate', id : 1},
      //     ],
      //   },
      //   {
      //   text : 'Proj #53323',
      //   to    : '/projects/53323',
      //   id    : 3,
      //   subchildren: [
      //   {text: 'Breeding values', to : '/projects/53323/reports/breeding_values', id : 1},
      //   {text: 'Yield estimate', to : '/projects/53323/reports/yield_estimate', id : 2},
      //   ],
      //   },
      //   {
      //   text : 'Proj #96756',
      //   to    : '/projects/96756',
      //   id    : 4,
      //   },

      //   ],
      //   },
      // ]


      this.navLinkItems = resultArr;

    },
    setMode() {
      this.$store.dispatch('powerBI/setViewMode');
    },
    setAvatarName(currentUser) {
      let name = currentUser.firstname+" "+currentUser.lastname;
      let initials = "-"
      if(currentUser.lastname.length == 0){
        initials = currentUser.firstname[0];
      }else{
        let regex = /^\s*([a-zA-Z]).*\s+([a-zA-Z])\S+$/gm;
        let subst = `$1$2`;
        initials = name.replace(regex, subst);
      }
      return initials;
    }
  }
};
</script>


<style scoped>
/* .v-main {
  background-color: #F2F2F2;
} */


.main-app{
  padding: 0px 0px 60px 0px  !important;
}
.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* background: url( 'http://localhost:8080//keygene-header-tulip.png') no-repeat center center; */
    background: url( '../public/keygene-header-tulip.png') no-repeat center center;
    background-size: cover;
    transform: scale(1.1);
}

.item-link {
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
 }

 .v-list-item-report{

  left:5px;
 }
.bggrey {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #F2F2F2;
  background-size: cover;
  transform: scale(1.1);
}
 .v-item-report-span{
  margin-left:30px;
 }

 .SelectedTile:hover {
  border-radius: 4px;
  background: #455A64;
}

.SelectedTile-active {
  border-radius: 4px;
  background: rgba(10, 204, 117, 0.19);
}

.v-avatar-user{
  border: 1px dotted white;
}

</style>


