<template>

  <v-container fluid>
    <v-row dense class="component-headline">
      <strong><h2>Overview</h2></strong> 
    </v-row>
    
    <v-row  dense>
      <v-col cols="12">
        <v-alert
      dismissible       
      type="info"
    >
    Welcome {{ currentUser.firstname }}, you're using an early access version. We value your input and would appreciate any feedback or suggestions you might have.
    </v-alert>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        cols="9"
      >
        <v-card
          height="100%"
        >
          <v-card-title>My projects
            <v-spacer></v-spacer>
            <v-text-field
              class="my-text-field"
              color="primary"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="projectHeaders"
                :page.sync="page"
                :items="this.projectData"
                :search="search"
                :item-class="getItemClass"
                item-key="project_name"
                :items-per-page="itemsPerPage"
                show-expand
                hide-default-footer
                @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              >
    
                <template v-slot:item.project_name="{ item }">
                  <!-- <a class="primary--text font-weight-bold text-decoration-none" :href="'projects/'+item.workspace_id" >{{ item.project_name }}</a> -->
                  <span class="primary--text font-weight-bold text-decoration-none">{{ item.project_name }}</span>
                </template>

                <template v-slot:item.ref_doc="{ item }">
                  <a href="#" download class="primary--text font-weight-bold text-decoration-none">{{ item.ref_doc.toUpperCase() }}
                  <v-icon color="primary">
                      mdi-download
                  </v-icon> </a>

                </template>

                <template v-slot:item.deliverables="{ item }">
                  <span  class="primary--text font-weight-bold text-decoration-none">{{ item.deliverables }}</span>
                </template>

                <template v-slot:item.deliverables_url="{ item }">
                  <v-btn
                  text
                  color="primary"
                  class="primary--text font-weight-bold text-decoration-none" 
                  :to="'projects/'+item.workspace_id" 
                  >
                  {{ item.deliverables_url }}
                  </v-btn>
                </template>

                   <!--expand row-->
                  <template v-slot:expanded-item="{ item }">
                    <td :colspan=7 >
                      <v-list>
                      <template v-for="(subitem, index)  in item.approved_reports">
                        <v-list-item :key="subitem.report_name" class="pa-0 report-expand-item">
                          <template>
                          <v-list-item-content>
                            <v-list-item-title class="text-subtitle-2 pa-1 ma-0 primary--text font-weight-bold text-decoration-none" v-text="subitem.report_name"></v-list-item-title>
                            <!-- <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle> -->
                            </v-list-item-content>
                            <v-btn
                            text
                            color="primary"
                            class="primary--text font-weight-bold text-decoration-none" 
                            :to="'projects/'+item.workspace_id+'/reports/'+subitem.report_id"
                            >
                            GO TO REPORT
                            </v-btn>
                          <!-- <span class="go-to-report-link"><p class="text-subtitle-2 pa-1 ma-0"><a class="primary--text font-weight-bold text-decoration-none" :href="'projects/'+item.workspace_id+'/reports/'+subitem.report_id">GO TO REPORT</a></p ></span> -->
                          </template>
                        </v-list-item>

                        <v-divider
                        v-if="index < item.approved_reports.length - 1"
                        :key="index"
                        ></v-divider>
                      </template>

                      </v-list>
                    </td>
                  </template>
                  
              </v-data-table>
            <v-card class="pa-3 v-card-project-suggestion"
              elevation="2"
            >
            <v-icon class="mb-2">mdi-lightbulb-on</v-icon>
            <span class="black--text font-weight-bold">PROJECT SUGGESTION</span> - <span class="black--text font-weight-light">Anker Sorenson </span> "Dear {{currentUser.firstname}}, the EDV-Breeding report is available for you!"</a>
            </v-card>

                <div class="d-flex ">
                <v-pagination
                class="project-pagination"
                v-model="page"
                :length="pageCount"
                ></v-pagination>

                </div>
  
            </v-card-text>

            <v-divider class="mx-4"></v-divider>
        </v-card>
      </v-col>

      <v-col 
      cols="3"

      >
        <v-card
          id="v-card-kg-contact"
          max-height="580px"
          min-height="560px"
          max-width="390px"
          min-width="300px"
        >
          <v-card-title class="justify-center">
           Hello {{ currentUser.firstname }}  {{ currentUser.lastname }}
            <!-- <v-card-subtitle class="text-subtitle-1"><strong>Hi {{ currentUser.username }}, I am your personal KeyGene contact</strong></v-card-subtitle> -->
          </v-card-title>
          <v-card-text class="text-center text-subtitle-1">I am your personal KeyGene contact</v-card-text>
          <v-card-actions>
            <v-row dense>
              <v-col
                align="center"
                class="spacer"
              >
                <v-avatar
                
                size="168"
                >
                  <!-- <v-img
                  src="people/Anker-Sorensen.jpg"
                  max-height="160"
                  max-width="160"
                  alt="Anker Sorensen"
                  >
                  </v-img> -->
                  <v-img
                  src="people/Shital-Dixit.jpg"
                  max-height="160"
                  max-width="160"
                  alt="Shital Dixit"
                  >
                  </v-img>


                </v-avatar>
              </v-col> 
            </v-row>
          </v-card-actions>
          <v-card-actions style="padding-top: 0px;padding-bottom: 0px;">
            <v-row dense>
            <!-- <v-card-text class="v-card-contact-name text-center text-subtitle-2">Anker Sorenson</v-card-text> -->
            <v-card-text class="v-card-contact-name text-center text-subtitle-2">Shital Dixit</v-card-text>
            
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-list class="transparent">
              <v-list-item class="contact-item ">
                <v-list-item-avatar>
                  <v-icon
                  color="indigo"
                  >
                  mdi-phone
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content >
                  <v-list-item-title class="item-mytitle" >+31 (0)317 466 866</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="contact-item">
                <v-list-item-avatar>
                  <v-icon
                  color="indigo"
                  >
                  mdi-email
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <!-- <v-list-item-title class="item-mytitle"><a href="#" class="text-decoration-none" >anker.sorenson@keygene.com</a></v-list-item-title> -->
                  <v-list-item-title class="item-mytitle"><a href="mailto:shital.dixit@keygene.com" class="text-decoration-none"  target="_top">shital.dixit@keygene.com</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="contact-item">
                <v-list-item-avatar>
                  <v-icon
                  color="indigo"
                  >
                  mdi-earth
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="item-mytitle">KeyGene Netherlands Office</v-list-item-title>
                  <v-list-item-subtitle class="item-mytitle">Office hours: 8:30- 17:00 (GMT+2)<br /></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-actions>

          <v-divider class="mx-4"></v-divider>
          <v-card-text class="text-center">
            <a class="primary--text font-weight-bold text-decoration-none" href="https://www.keygene.com/contact">VISIT KEYGENE CONTACT PAGE
              <v-icon color="primary">
                mdi-chevron-right
              </v-icon> 
             </a>
          </v-card-text>

   

        </v-card>
      </v-col>

    </v-row>

  

     <!--v-card kg app bar-->
     <v-row dense>
        <v-col cols="12">
          <v-card height="100%" 
          id="v-card-kg-discomore"
          >
          <v-card-title class="pb-0">KeyGene Application </v-card-title>
          <v-row class="v-card-discomore"
            dense    
            no-gutters>
          <v-col cols="4">
            <!-- newspost #1-->
            <v-card 
              class="pa-3 v-card-post-item"
              outlined
              tile
              color="transparent"
            >
            <!-- <v-card-title style="height:60px" class="pa-0 item-mytitle">Croppedia Mahindra</v-card-title>
            <v-row style="height:20px" dense no-gutters><v-col cols="6"><v-card-subtitle class="pa-0 item-mysubtitle" align="left">Croppedia Mahindra </v-card-subtitle></v-col><v-col cols="6">  <v-card-subtitle class=" pa-0 item-mysubtitle" align="right">Sept 29, 2022 </v-card-subtitle></v-col></v-row>
             -->
             <a href="https://portal.croppedia.com">

     
            <v-img
            height="170"
            src="/croppedia-main.png"
            class="rounded-lg"
            >
            </v-img>
          </a>
            <v-card-text class="pa-0">  <a class="primary--text font-weight-bold text-decoration-none" href="https://portal.croppedia.com">VISIT CROPPEDIA MAHINDRA   <v-icon color="primary">
              mdi-chevron-right
                  </v-icon> 
              </a></v-card-text>
          </v-card>
     
          </v-col>
          <v-col cols="4">
            <!-- newspost #2-->
            <v-card
              class="pa-3 v-card-post-item"
              outlined
              tile
              color="transparent"
              
            >
            <!-- <v-card-title style="height:60px" class="pa-0 item-mytitle"></v-card-title>
            <v-row style="height:20px" dense no-gutters><v-col cols="6"><v-card-subtitle class="pa-0 item-mysubtitle" align="left">TECHNOLOGY </v-card-subtitle></v-col><v-col cols="6">  <v-card-subtitle class="pa-0 item-mysubtitle" align="right"> </v-card-subtitle></v-col></v-row>
             -->
             <a href="https://www.keygene.com/keypoint/">


              <v-img
              height="170"
              src="bg-images/keypoint_logo.png"
              class="rounded-lg"
              ></v-img>
            </a>
    
            
              <v-card-text class="pa-0">  <a class="primary--text font-weight-bold text-decoration-none" href="https://www.keygene.com/keypoint/"> VISIT TO KEYGENE KEYPOINT WEB PAGE <v-icon color="primary">
                mdi-chevron-right
                    </v-icon> 
                </a></v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
              <!-- newspost #3-->
              <v-card
              class="pa-3 v-card-post-item"
              outlined
              tile
              color="transparent"
            >
            <!-- <v-card-title style="height:60px" class="pa-0 item-mytitle"></v-card-title>
            <v-row style="height:20px" dense no-gutters><v-col cols="6"><v-card-subtitle class="pa-0 item-mysubtitle" align="left">TECHNOLOGY </v-card-subtitle></v-col><v-col cols="6">  <v-card-subtitle class="pa-0 item-mysubtitle" align="right"> </v-card-subtitle></v-col></v-row>
            
           -->
           <a href="https://www.keygene.com">

   
              <v-img
              height="170"
              src="bg-images/keygene_new_website.png"
              class="rounded-lg"
              ></v-img>

            </a>

              <v-card-text class="pa-0">  <a class="primary--text font-weight-bold text-decoration-none" href="https://www.keygene.com"> VISIT KEYGENE HOME PAGE <v-icon color="primary">
                mdi-chevron-right
                    </v-icon> 
                </a></v-card-text>
            </v-card>
          </v-col>
        </v-row>
          </v-card>

        </v-col>

      </v-row>


    <!--v-card Discover More-->
      <v-row dense>
        <v-col cols="12">
          <v-card height="100%" 
          id="v-card-kg-discomore"
          >
          <v-card-title>Discover more </v-card-title>
          <v-row class="v-card-discomore"
            dense    
            no-gutters>
          <v-col cols="4">
            <!-- newspost #1-->
            <v-card 
              class="pa-3 v-card-post-item"
              outlined
              tile
              color="transparent"
            >
            <v-card-title style="height:60px" class="pa-0 item-mytitle">KeyGene early adopter of MGI sequencing</v-card-title>
            <v-row style="height:20px" dense no-gutters><v-col cols="6"><v-card-subtitle class="pa-0 item-mysubtitle" align="left">NEWSPOST </v-card-subtitle></v-col><v-col cols="6">  <v-card-subtitle class=" pa-0 item-mysubtitle" align="right"></v-card-subtitle></v-col></v-row>
            
            <v-img
            height="170"
            src="/hands-plant.jpg"
            class="rounded-lg"
            >
            </v-img>

            <v-card-text class="v-card-post-content">
              This week, KeyGene installed an MGI DNA-sequencing machine: a DNBSEQ – G400RS. KeyGene is the first to acquire and install this new sequencer in the Netherlands, a leading country in plant breeding and plant research.

“The new MGI sequencing technology offers a range of new possibilities for technology innovation for crop improvement’ said Dick Roelofs, KeyGene team leader DNA-Technologies. The machine uses a unique type of chemistry based on DNA NanoBalls (DNBs) synthesized through rolling circle amplification. This opens up opportunities for KeyGene to explore new avenues of innovation. “And of course, we want to verify how the innovative DNA-technologies earlier developed by KeyGene, such as KeyPoint and SNPSelect, can optimally be applied using this MGI’s sequencer.

The MGI DNA-sequencer also offers other innovative possibilities for research projects at KeyGene in emerging fields like single-cell genomics.
            </v-card-text>

            <v-card-text class="pa-0">  <a class="primary--text font-weight-bold text-decoration-none" href="https://www.keygene.com/news-events/keygene-early-adopter-of-mgi-sequencing/">MORE INFORMATION   <v-icon color="primary">
              mdi-chevron-right
                  </v-icon> 
              </a></v-card-text>
          </v-card>
     
          </v-col>
          <v-col cols="4">
            <!-- newspost #2-->
            <v-card
              class="pa-3 v-card-post-item"
              outlined
              tile
              color="transparent"
            >
            <v-card-title style="height:60px" class="pa-0 item-mytitle">About CropPedia</v-card-title>
            <v-row style="height:20px" dense no-gutters><v-col cols="6"><v-card-subtitle class="pa-0 item-mysubtitle" align="left">TECHNOLOGY </v-card-subtitle></v-col><v-col cols="6">  <v-card-subtitle class="pa-0 item-mysubtitle" align="right"> </v-card-subtitle></v-col></v-row>
            
          
              <v-img
              height="170"
              src="bg-images/plants-research.jpg"
              class="rounded-lg"
              ></v-img>

              <v-card-text class="v-card-post-content">
                The leading knowledge platform and database management system for fast and effective marker development and lead gene discovery. The ultimate software tool to speed up molecular breeding by easily tracking and selecting trait-associated leads. Work together globally and optimize the output of team efforts through the web-based gene discovery platform. Let molecular breeders team up with trait specialists and bio-informaticians. Share results in dedicated workspaces. Connect to accelerate breeding.
              </v-card-text>

              <v-card-text class="pa-0">  <a class="primary--text font-weight-bold text-decoration-none" href="https://www.croppedia.com">MORE INFORMATION   <v-icon color="primary">
                mdi-chevron-right
                    </v-icon> 
                </a></v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
              <!-- newspost #3-->
              <v-card
              class="pa-3 v-card-post-item"
              outlined
              tile
              color="transparent"
              
            >
            <v-card-title style="height:60px" class="pa-0 item-mytitle">About KeyPoint® </v-card-title>
            <v-row style="height:20px" dense no-gutters><v-col cols="6"><v-card-subtitle class="pa-0 item-mysubtitle" align="left">TECHNOLOGY </v-card-subtitle></v-col><v-col cols="6">  <v-card-subtitle class="pa-0 item-mysubtitle" align="right"> </v-card-subtitle></v-col></v-row>
            
          
              <v-img
              height="170"
              src="bg-images/data-science-shutterstock-lr-1179727588-scaled.jpg"
              class="rounded-lg"
              ></v-img>

              <v-card-text class="v-card-post-content">
                The invention encompasses the multiplex detection of genetic variation of genes and/or loci of interest based on high throughput sequencing technologies. Using multidimensional pooling and/or barcoding techniques, genetic variations detected in pooled sequence libraries can be easily traced back to the respective individuals. The invention is currently widely applied in both agro-genomics for screening of variation in mutagenized populations, as well as in health care for instance in screening targets for cancer detection.
              </v-card-text>
            
              <v-card-text class="pa-0">  <a class="primary--text font-weight-bold text-decoration-none" href="https://www.keygene.com/keypoint/">MORE INFORMATION   <v-icon color="primary">
                mdi-chevron-right
                    </v-icon> 
                </a></v-card-text>
            </v-card>
          </v-col>
        </v-row>
          </v-card>

        </v-col>

      </v-row>
     
   </v-container>
  
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import Reports from './powerbi/Reports.vue'

  import axios from 'axios';
  import endpoint from '../store/endpoint.js';
  import authHeader from '../services/authHeader';

  import store from '../store';

  export default {
    name: 'Overview',
    data() {
      return {
   
        page: 1,
        pageCount: 1,
        itemsPerPage: 4,
        search: '',
        expanded: [],
        singleExpand: true,
        projectHeaders: [
          {
            text: 'PROJECT NAME',
            align: 'start',
            sortable: false,
            value: 'project_name',
          },
          { text: 'REF. DOCUMENT', value: 'ref_doc' },
          { text: 'PROJECT TYPE', value: 'project_type' },
          { text: 'STATUS', value: 'project_status' },
          { text: 'DELIVERABLES', value: 'deliverables' },
          { text: '', value: 'deliverables_url' },
          { text: '', value: 'data-table-expand' },
        ],
        projectData: [//to be filled in watch()
        ],
        projectDetails: [
          {
          project_name: 'Proj #776782',
          ref_doc: 'pdf',
          project_type: 'Statistics and genetics',
          project_status: 'Done',
          deliverables: 3,
          deliverables_url: 'OPEN PROJECT',
          approved_reports: [
            {
            report_id: 'asd',
            report_name: 'title1',
            },
            {
            report_id: 'asd',
            report_name: 'title2',
            },
            {
            report_id: 'asd',
            report_name: 'title3',
            }
          ]
          },
          {
          project_name: 'Proj #81272',
          ref_doc: 'pdf',
          project_type: 'Phenotype',
          project_status: 'Done',
          deliverables: 4,
          deliverables_url: 'OPEN PROJECT',
          },
          {
          project_name: 'Proj #XXXXX',
          ref_doc: 'pdf',
          project_type: 'Statistics and genetics',
          project_status: 'Done',
          deliverables: 6,
          deliverables_url: 'OPEN PROJECT',
          },
          {
          project_name: 'Proj #123422',
          ref_doc: 'pdf',
          project_type: 'Statistics and genetics',
          project_status: 'Done',
          deliverables: 1,
          deliverables_url: 'OPEN PROJECT',
          },
          {
          project_name: 'Proj #43521',
          ref_doc: 'pdf',
          project_type: 'Biologicals',
          project_status: 'Done',
          deliverables: 3,
          deliverables_url: 'OPEN PROJECT',
          },
          {
          project_name: 'Proj #57443',
          ref_doc: 'pdf',
          project_type: 'Biologicals',
          project_status: 'Inprogress',
          deliverables: "Not available yet",
          deliverables_url: '',
          },
          {
          project_name: 'Proj #53234',
          ref_doc: 'pdf',
          project_type: 'Biologicals',
          project_status: 'Inprogress',
          deliverables: "Not available yet",
          deliverables_url: '',
          },
          {
          project_name: 'Proj #34511',
          ref_doc: 'pdf',
          project_type: 'Biologicals',
          project_status: 'Inprogress',
          deliverables: "Not available yet",
          deliverables_url: '',
          },
          {
          project_name: 'Proj #24512',
          ref_doc: 'pdf',
          project_type: 'Biologicals',
          project_status: 'Inprogress',
          deliverables: 4.9,
          deliverables_url: '',
          },
          {
          project_name: 'Proj #123124',
          ref_doc: 'pdf',
          project_type: 'Biologicals',
          project_status: 'Transfering samples',
          deliverables: "Not available yet",
          deliverables_url: '',
          },
        ],
      }
    },
    mounted() {
      // console.log("overview mounted");
      const loggedIn = store.getters['auth/isAuthenticated'];
    //  console.log("loggindin")
    //  console.log(loggedIn);
    //  console.log(store.getters['auth/authStatus'])
    },
    watch: {
      projects() {
        this.projectRawData = this.projects;
   
        let newAr = [];
        var reportArr = [];
   
        var newObJ = {}
        for (const [pj_obj_idx, pj_obj] of Object.entries(this.projectRawData)) {
          var newObJ = {}

          //push report id and name to hasharray 
          if (reportArr[pj_obj.project_name]) { 
            reportArr[pj_obj.project_name].push({report_id: pj_obj.report_id, workspace_id: pj_obj.workspace_id, report_name:pj_obj.description})
          } else {
            reportArr[pj_obj.project_name] = [{report_id: pj_obj.report_id, workspace_id: pj_obj.workspace_id, report_name:pj_obj.description}]
          }

          // loop inside obj:
          for (const [key, value] of Object.entries(pj_obj)) {
            if (key === "project_name"){
              newObJ[key] = value
            }else if(key === "project_status"){
              newObJ[key] = value
            }else if(key === "project_type"){
              newObJ[key] = value
            }else if(key === "workspace_id"){
              newObJ[key] = value
            }
            newObJ["ref_doc"] = "pdf"
            newObJ["deliverables_url"] = "Open Project"
            newObJ["deliverables"] = "Available"
          }
          newAr.push(newObJ);
        
        }

        function getUniqueListBy(newAr, key) {
        return [...new Map(newAr.map(item => [item[key], item])).values()]
        }
        // main project structure is ready
        const arr1 = getUniqueListBy(newAr, 'workspace_id')
        this.projectData = arr1;
      
        // add reports data in the arr1 object
        for (const [pj_obj_idx, pj_obj] of Object.entries(arr1)) {
 
              pj_obj["deliverables"] = reportArr[pj_obj.project_name].length
              pj_obj["approved_reports"] = reportArr[pj_obj.project_name]
              
        
        }
      },
      approvedReports(){
        this.approvedReportData = this.approvedReports;
      },
   
    },
    created() {
      // get workspace for user.
      this.$store.dispatch('powerBI/getWorkspaces', this.currentUser.id);
      this.$store.dispatch('projects/retrieveAllProjects', this.currentUser.id);
    },
    components: {
      Reports
    },
    computed: {
      ...mapGetters('auth',[
        'currentUser'
      ]),
      ...mapGetters('powerBI',[
        'reports'
      ]),
      ...mapGetters('projects',[
        'projects',
        'users',
        'approvedReports'
      ]),
    },
    methods: {
      getItemClass: function (item) {
        return 'grey';
      },
      downloadItemPas(){
        console.log("download pas doc");
        axios.get(endpoint + '/api/v1/files/pas',{	headers: authHeader(), responseType: 'blob'}).then((response) => {

          console.log("response");

          console.log(response);
          let blob = new Blob([response.data], {type:'application/*'})
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = "MASL2102Pb_PAS_Heterotic_pool_development.pdf";
          link.click();
          URL.revokeObjectURL(link.href);
          }).catch(error => {
          console.log(error);
          });
      }
    }
  };
  </script>

  <style scoped lang="css">
  #v-card-kg-contact .item-mytitle {
    font-size: 0.9rem;
  }

  #v-card-kg-discomore .item-mytitle {
    font-size: 1.1rem;
  }
  #v-card-kg-discomore .item-mysubtitle {
    font-size: 0.7rem;
  }

  .my-text-field {
    background-color: #F7F7F7;
  }

  .v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none !important;
  }


  .project-pagination{
   
  }

  .contact-item{
    padding-left:45px;
    padding-right:10px;
  }

  .project-table-item{
    background-color: red;
  }

  .component-headline{
    margin:0px;
    padding-top:0;
    padding-bottom:5px;
  }

  .component-breedcrumbs{
    margin:0px;
    padding-top:0;
    padding-bottom:5px;
  }

  .v-breadcrumbs{
    padding:0;
  }

  .v-card-contact-name{
  padding:0;
  }

  .v-card-discomore{
    margin-left:25px;
    margin-right:25px;
  }

  .v-card-post-content{
    padding-left:0;
    padding-right:0;
    margin-bottom:10px;
    height:125px;
    overflow: hidden;
  }

  .v-card-project-suggestion{
    margin-top:15px;
    margin-bottom:5px;
  }
  
  table.v-table tbody tr td {
  font-size: 16px;
  height: 15px;
  padding: 0px;
}

.v-list-expand{
  width: 700px;
}

.report-expand-item{
  margin-left:20px;
}

.go-to-report-link{
  margin-right:60px;
}

.rounded-card{
    border-radius:50px;
}

</style>