<template>

  <v-container fluid>
    <v-row dense class="component-headline">
      <strong><h2>Admin Dashboard</h2></strong> 
    </v-row>

    <v-row dense>
      <v-col cols="8">
        <v-card style="height: 100%" >
          <v-card-title>Available projects</v-card-title>
          <v-card-title v-if="datatableLoading">      
            <v-progress-linear
            color="purple darken-4"
            height="10"
            striped
            indeterminate

            ></v-progress-linear>
            Please wait,  still loading report details...
          </v-card-title>
   
          <v-card-title>
            <v-text-field
            v-model="search"
            class="my-search-field"
            color="primary"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            ></v-text-field>
          </v-card-title>

          <v-card-text>
            <v-data-table
            :loading="datatableLoading" 
            loading-text="Please wait.."
            :headers="reportHeaders"
            :items="reportData"
            :items-per-page="10"
            :search="search"
            group-by="workspace"
            @click:row="getApprovalTable"
            >

     
            <template v-slot:group.header="{ group, headers, toggle, isOpen }">
            <td :colspan="headers.length" class="secondary">
            <v-btn @click="toggle" x-small icon :ref="group">
            <v-icon v-if="isOpen">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
            <span class="mx-5 font-weight-bold">{{ group }}</span>
            </td>
            </template>

            <template v-slot:item.approved="{ item }">
            <v-chip v-if="item.approved"
            class="ma-2"
            color="green"
            text-color="white"
            >
            <v-avatar
            left
            class="green darken-4"
            >
            <v-icon>mdi-checkbox-marked-circle</v-icon>
            </v-avatar>
            Approved  
            </v-chip>

            <v-chip v-else
            class="ma-2"
            color="grey darken-1"
            text-color="white"
            >
            <v-avatar
            left
            class="grey darken-2"
            >
            <v-icon>mdi-checkbox-blank-circle-outline </v-icon>

            </v-avatar>
            Not ready
            </v-chip>

            </template>

            <template v-slot:item.modified_date="{ item }">
            <small>{{  formatDate(item.modified_date)  }}</small>
            </template>

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card 
          id="v-card-admin-box2"
          max-height="550px"
          min-height="540px"
          max-width="490px"
          min-width="450px"
          elevation="1" v-if="selectedReport"
          >
          <v-card-title v-if="selectedReport">Report: {{selectedReport}}</v-card-title>
          <v-card-subtitle v-if="selectedReport">{{selectedDate}}</v-card-subtitle>
          <v-alert v-if="dateWarning"
            border="top"
            color="red lighten-2"
            dark
            >
            {{dateWarning}}
          </v-alert>

          <v-card-text>
            <v-data-table
            :headers="approvedHeaders"
            :items="approveData"
            hide-default-footer
            >

            <template v-slot:item.approval_date="{ item }">
            <small>{{ formatDate(item.approval_date) }}</small>
            </template>

            <template v-slot:item.username="{ item }">
            <small> {{ (item.username == currentUser.username) ? "me" : item.username  }} </small>
            </template>

            </v-data-table>
          </v-card-text>

          <v-card-subtitle>
          This report will be automatically published when two admins have approved.
          </v-card-subtitle>
            <v-card-text>
              <v-switch
              v-model="approveToggle"
              label="Approve for publishing"
              >
              </v-switch>
              <v-spacer></v-spacer>
              <v-btn
              @click="updateTable"
              color="primary"
              >update
              </v-btn>
            </v-card-text>
         
        </v-card>
      </v-col>
    </v-row>

<v-row>
<v-col>
<v-card style="min-height: 1000px; height: 100%">
<v-card-title>
Report 
</v-card-title>

<Dashboard/>

</v-card>
</v-col>
</v-row>

<Outlink/>
</v-container>

</template>

<script>

  import { mapGetters } from 'vuex';

  import Dashboard from './Dashboard.vue'
  import Outlink from './Outlink.vue'

  export default {
    name: 'App',
    data() {
      return {
        search: '',
        reportHeaders : [{
          text: 'WORKSPACE',
          value: 'workspace',
          groupable: true,
          },
          {
          text: 'REPORT',
          value: 'description',
          groupable: false,
          },
          {
          text: 'MODIFIED DATE',
          value: 'modified_date',
          groupable: false,
          },
          {
          text: 'APPROVAL',
          value: 'approved',
          groupable: false,
        }],
      reportData: [],
      approvedHeaders : [
      {
      text: 'APPROVAL DATE',
      value: 'approval_date',
      },
      {
      text: 'APPROVED BY',
      value: 'username',
      }
      ],
      approveData : [],
      selectedReport : null,
      selectedReportID : null,
      selectedDate : null,
      approveToggle : false,
      dateWarning : null,
      datatableLoading: true,
      }
    },
    components: {
      Dashboard,
      Outlink
    },
    mounted() {
      //https://stackoverflow.com/questions/72287268/v-if-not-updating
      setTimeout(() => {
      this.datatableLoading = false;
      }, 5000)
      //this.datatableLoading = false;
    }, 
    created() {
      // console.log("created()")
      this.$store.dispatch('admin/retrieveAllReports');
    },
    computed: {
      ...mapGetters('admin',[
        'reports',
        'users',
        'approvalTable'
      ]),
      ...mapGetters('auth',[
        'currentUser',
      ]),
      ...mapGetters('powerBI',[
        'rendering',
      ])
    },
    watch: {
      rendering() {
        // console.log("in rendering..")
        // console.log(this.rendering)
        this.datatableLoading = this.rendering;
      },
      reports() {
        // console.log("in reports()..")
        // console.log(this.reports)
        this.reportData = this.reports;
      },
      approvalTable() {
        // console.log("in approvalTable:")
        // console.log(this.approvalTable)
        this.approveData = this.approvalTable;
        if(this.approveData.length > 0) {
        try {
          for(let i in this.approveData) {

            this.approveData[i].numApproved = i + 1;
            if( this.approveData[i].user_id == this.currentUser.id ) {
            this.approveToggle = true;
            }
            const approvalDate = Date(this.approveData[i].approval_date);
            if(this.selectedDate > approvalDate) {
            this.dateWarning = "Warning! report modified after approval.";
            }
          }
        }
        catch(error) {
          console.log(error.message)
        }
      }
      this.$store.dispatch('admin/retrieveAllReports');
    },
    linkOptions() {
    // console.log(this.linkOptions);
    }
  },
  methods: {
    getApprovalTable(item) {
      // start PowerBI
      this.$store.dispatch('powerBI/getReportToken', 
      {  
        workspaceId: item.workspaceId,
        reportId: item.reportId
      });
      // console.log("start rendering report wait plz")
      // console.log(this.rendering);
      this.datatableLoading = true; //start rendering, this the jwt token fix temporary
      this.approveToggle = false;
      this.update = true;
      this.$store.dispatch('admin/retrieveApprovalTable', item.report_id);
      this.selectedReport = item.description;
      this.selectedDate =  new Date(item.modified_date);
      this.selectedReportID = item.report_id;
      this.dateWarning = null;
    },
    updateTable() {
      // console.log("in updateTable()")
      this.$store.dispatch('admin/approveReport', 
      {
        report_id: this.selectedReportID, 
        user_id : this.currentUser.id
      });
    },
    formatDate(dateString) {
      return new Date(dateString);
    },
    setdatatableLoading() {
      this.datatableLoading = false;
    },
  },
}
</script>

<style scoped lang="css">
  .component-headline{
    margin:0px;
    padding-top:0;
    padding-bottom:5px;
  }

  .my-search-field {
    background-color: #F7F7F7;
  }

</style>