<template>
  <v-simple-table
    fixed-header
    height="400px"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Reports
          </th>
          <th>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in reports"
          :key="i"
        >
          <td><v-icon>mdi-chart-box</v-icon> {{ item.description }}</td>
          <td>
            <v-btn @click="getReport(item)"
              color="primary"
              elevation="2"
              x-small
              :loading=reportLoading
            >
              select
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'Reports',
  data() {
    return {
      reportLoading: false,
      item: null,
    };
  },
    computed: {
        ...mapGetters('powerBI',[
            'reports',
            'tokenObject'
        ]),
        ...mapGetters('settings',[
            'workspace',
        ]),
    },
    watch: {
    tokenObject() {
      this.reportLoading = false;
      this.$router.push({name: "dashboard"});
    },
    workspace() {
      this.$store.dispatch('powerBI/getReports', this.workspace.id);
    },
  },
  methods: {
      getReport(report) {
        this.reportLoading = true;
        this.$store.dispatch('powerBI/getReportToken', {workspaceId: this.workspace.workspaceId, reportId: report.reportId});
        this.$store.dispatch('settings/setActiveReport', report);
      }
    }

};
</script>