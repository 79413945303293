import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import store from './store/index.js';
import router from './router/index.js'


// dismisses warnings in the console
Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')