const state = {
    workspace: null,
    report: null,
    table: null,
    column: null,
    bookmark: null,
    active: {}
}

const mutations = {
    UPDATE_ACTIVE_WORSPACE (state, payload) {
        state.workspace = payload;
        // console.log("workspace stored");
    },
    UPDATE_ACTIVE_REPORT (state, payload) {
        state.report = payload;
        // console.log("report stored");
	},
}

const actions = {
    setActiveWorkspace (context, workspace ) {
		context.commit('UPDATE_ACTIVE_WORSPACE', workspace)
    },
    setActiveReport (context, report ) {
		context.commit('UPDATE_ACTIVE_REPORT', report)
	},
}

const getters = {
    workspace: state => state.workspace,
    report: state => state.report
};

const settingsModule = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
export default settingsModule;