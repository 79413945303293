<template>
    <div></div>
</template>

<script>

export default {
  name: "logout",
  data() {
    return {
    };
  },
  created() {
    this.$store.dispatch('powerBI/resetSaveState');
    this.$store.dispatch('auth/authLogout')
    .then(() => {
      if(this.$route.params.error) {
        this.$router.push({name: 'login', params: {error: this.$route.params.error } })
      }
      else {
        this.$router.push({name: "login"})
      }
      });
  },
  methods: {  }
};
</script>