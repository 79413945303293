<template>
  <!-- <section id="reportContainer" class="embed-container"></section> -->

 <iframe title="MASL_Demo" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=beefbb06-3958-4e64-b1b4-67cb0f4a730b&autoAuth=true&ctid=a71f7b36-8f38-4fad-93d5-67dabaff9a46" frameborder="0" allowFullScreen="true"></iframe>
</template>

<script>

import * as pbi from 'powerbi-client';
import * as pbm from 'powerbi-models';
import { mapGetters } from 'vuex';


export default {
  name: 'powerbi',
  data() {
    return {
      report: null,
      powerBIResponse : '',
      datatableLoading: false,
    };
  },
  mounted() {
    this.initPowerBI();
    
    if(this.tokenObject !== "") {
      this.renderReport();
    }
  },
  computed: {
        ...mapGetters('powerBI',[
            'viewMode',
            'tokenObject',
            'activeElement',
            'saveState',
            'linkTable',
            'linkColumn',
            'rendering' 
        ]),
    },
  watch: {
    viewMode() {
      // console.log("viewMode!")
      this.report.switchMode(this.viewMode);
    },
    tokenObject() {
      this.renderReport();
    }

  },
  created() {
  },
  methods: {
    renderReport() {
      // console.log("re-render in Dashboard.vue");
      // console.log(this.rendering);
      const user = this.$store.getters['auth/currentUser'];

      let models = pbm;
      // console.log(models);
      let loadConfig = {
        type: "report",
        tokenType: models.TokenType.Embed,
        accessToken: this.tokenObject.accessToken,
        embedUrl: this.tokenObject.embedUrl,
        pageView: 'fitToWidth',
        pageName: '',
        settings: {
          background: models.BackgroundType.Transparent,
          layoutType: models.LayoutType.Custom,
          customLayout: {
              displayOption: models.DisplayOption.FitToWidth,
          },
          // filterPaneEnabled: false,  // filter options
          // navContentPaneEnabled: true, // bottom page select bar
          bars: {
            actionBar: {
            visible: true
            }
          },
          panes: {
            bookmarks: {
                visible: false
            },
            fields: {
                expanded: true
            },
            filters: {
              visible: false,
              expanded: true,
              
            },
            pageNavigation: {
                visible: true,
                position: models.PageNavigationPosition.Left,
            },
            selection: {
                visible: true
            },
            syncSlicers: {
                visible: true
            },
            visualizations: {
                expanded: true
            }
          },
        },
        localeSettings: {
          language: "en",
          formatLocale: "en"
        }
      };

      let adminOptions = {};
      if(user.role == "admin") {
        adminOptions = {
          permissions: models.Permissions.All,  // Read, ReadWrite, Copy, Create, All
          viewMode: models.ViewMode.View,  // View or Edit
        }
      }

      loadConfig = Object.assign(loadConfig, adminOptions);

    this.report = this.powerbi.embed(this.container, loadConfig);

    this.report.off("loaded");
    
    // Report has finished loading script and data but has not rendered yet
    this.report.on("loaded", function() {
        console.log("loaded report(s)");           
        // If you want to hide to powerbi loading icon, you can use a overlay inside the iframe and hide it when its triggered here
        // Here we should add a function to refresh the token before it expires
    });

    this.report.off("rendered");

    // When the report has rendered, this will fire
    this.report.on("rendered", function() {
        // console.log("report rendered ");
        self.$store.dispatch('powerBI/setRendering', false);
    });

    // hook to grab data
    var self = this;
    this.report.off("dataSelected");
    this.report.on("dataSelected", function(event) {
        try {
          var data = event.detail;
          var datapoint = data.dataPoints[0];

          
          // detect tables and columns
          if(self.saveState === "") {
            self.calibrateInteraction(datapoint);
          }
          else {
            for (var point in datapoint.identity) {
              // console.log(self.linkColumn);
              if(datapoint.identity[point].target.table == self.linkTable) {
                if(datapoint.identity[point].target.column == self.linkColumn) {
                  let selected = datapoint.identity[point].equals;
                  // console.log(selected);
                  self.$store.dispatch('powerBI/setActiveElement', selected);
                }
              }
              // var str = JSON.stringify(datapoint.identity[point] , null, 4);
              // console.log( str )
            }
          }
        }
        catch(error) {
          console.log(error.message);
          self.$store.dispatch('powerBI/setActiveElement', null);
        }
    });

    this.report.off("error");
    this.report.on("error", function(event) {
        let msg = event.detail;
        console.error(`Error: ${msg}`);
    });
  
    },
    initPowerBI() {
      this.container = document.getElementById('reportContainer');
      this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      this.powerbi.bootstrap(this.container, { type: "report"});
    },
    calibrateInteraction(datapoint) {
      // console.log("calibrating");
      try {
        let tables = [];
        let columns = [];

        for (var point in datapoint.identity) {
          // detect column
          tables[datapoint.identity[point].target.table] = true;
          columns[datapoint.identity[point].target.column] = true;
        }

        const linkOptions = {'tables': Object.keys(tables), 'columns': Object.keys(columns) };
        this.$store.dispatch('powerBI/setLinkOptions', linkOptions);

      }
      catch(error) {
        console.log(error)
      }
    }

  }

};


</script>


<style scoped lang="css">
  #reportContainer{
    width: 100%;
    height: 100%;
    border: none;
  }
</style>