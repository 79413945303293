<template>
  <v-container fluid>

    <v-row dense>
      <strong><h3>{{projectname}}</h3></strong>
      <!-- <strong><h3> {{ $route.params.projectid}}</h3></strong> -->
      <!-- breadcrumbs -->
    </v-row>
    <v-row dense>
      <v-breadcrumbs :items="breadcrumbitems" class="project-breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
      </v-breadcrumbs>
    </v-row>

    <v-row dense>
      <v-col cols="8">
        <v-card
          height="100%"
        >
        <v-list-item class="pa-0">
          <v-card-title class="pa-0">
            <v-card-title class="text-title-1"><strong>Reports</strong></v-card-title>
          </v-card-title>
        </v-list-item>

          <div class="Reports-subcard">
       
              <v-list>
                <v-list-item-subtitle class="font-weight-bold greydark--text report-subtitle">
                  REPORT
                </v-list-item-subtitle>
          
                <v-divider></v-divider>
                <template v-for="(item, index) in reportitems">
                  <v-list-item :key="item.to" class="pa-0">
                  <template>
                    <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <!-- <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle> -->
                    </v-list-item-content>
                    <v-btn
                    color="primary"
                    elevation="2"
                    :to="item.href"
                    >
                    {{item.action}}
                    </v-btn>
                  </template>
                  </v-list-item>

                  <v-divider 
                  v-if="index < reportitems.length - 1"
                  :key="index"
                  ></v-divider>
                </template>

              </v-list>
      
          </div>

        </v-card>
      </v-col>
      
      <v-col cols="4">
        <v-card
          height="100%"
        >
          <v-card-title class="pa-0">
            <v-card-title><strong>Files and assets</strong></v-card-title>
          </v-card-title>

          <div class="files-assets-subcard">

            <v-list class="project-files-list pa-0 ">
              <v-list-item-subtitle class="font-weight-bold greydark--text">
                PROJECT FILES 
              </v-list-item-subtitle>
            <v-list-item-group >
              <template >
              <v-list-item v-for="(item, index) in projectfiles" 
                :key="index" class="pa-0"
    
                target="_blank"
                @click.prevent="fileActionClick(item.id)""
                >
                <template >
                  <v-icon color="primary">
                      {{item.icon}}
                    </v-icon>
                  <v-list-item-content>
                    <v-list-item-title >{{item.title}} ({{item.extension}})</v-list-item-title>
                  </v-list-item-content>
                </template>
              
              </v-list-item>
          
              </template>
            </v-list-item-group>
            </v-list>
        </div>

        <!-- <div class="files-assets-subcard">

          <v-list >
            <v-list-item-subtitle class="font-weight-bold greydark--text">
              YOUR UPLOADS 
            </v-list-item-subtitle>
            <v-list-item-group>
            <template >
            <v-list-item v-for="(item, index) in uploadfiles" 
              :key="index"
              class="pa-0"
              :to="item.href"
              target="_blank"
              >
              <template >
                <v-icon color="primary">
                    {{item.icon}}
                  </v-icon>
                <v-list-item-content>
                  <v-list-item-title>{{item.title}}({{item.extension}})</v-list-item-title>
                </v-list-item-content>  
              </template> 
            </v-list-item>
            </template>
          </v-list-item-group>
          </v-list>
        </div> -->

<!-- 
          <div class="files-assets-subcard">
          <v-list >
          <v-file-input
          label="Click here/drag and drop file to upload"
          filled
          counter
          multiple
          truncate-length="35"
          prepend-icon=""
          prepend-inner-icon="mdi-upload"
          ></v-file-input>
          </v-list>
          </div> -->
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>


<script>
import { mapGetters } from 'vuex';
import { useRoute } from 'vue-router'

import axios from 'axios';
import endpoint from '../store/endpoint.js';
import authHeader from '../services/authHeader';

export default {
    name: 'Project',
    data: () => ({
      projectname: null,
      projecttype: null,
      breadcrumbitems: [],
      reportitems: [],
    projectfiles: [
    {
        extension: '.pdf',
        icon: 'mdi-download',
        title: 'MASL2102Pb PAS Heterotic pool development',
        href: 'downloads/download.pdf',
        id:'1',
      },
      {
        extension: '.pdf',
        icon: 'mdi-download',
        title: 'Heterotic grouping results',
        href: 'downloads/download.pdf',
        id:'2',
      },
      {
        extension: '.pdf',
        icon: 'mdi-download',
        title: 'Heterotic grouping additional results',
        href: 'downloads/download.pdf',
        id:'3',
      },
      {
        extension: '.csv',
        icon: 'mdi-download',
        title: 'Distance matrix all',
        href: 'downloads/download.csv',
        id:'4',
      },
      // {
      //   extension: 'YouTube',
      //   icon: 'mdi-open-in-new',
      //   title: 'Video demo',
      //   href: '//www.youtube.com/watch?v=yi_l-NQq2nY',
      //   id:'4',
      // },
    ],
    uploadfiles: [
      {
        extension: '.xls',
        icon: 'mdi-download',
        title: 'Reference data',
        href: 'download.xls',
        id:'1',
      },
      {
        extension: '.jpg',
        icon: 'mdi-download',
        title: 'Photo of subject',
        href: 'download.jpg',
        id:'2',
      },
 
    ],
    }),
    mounted() {
    },
    watch: {
      projects() {
        // Refactor below
        this.projectRawData = this.projects;
        this.getBreadcrumbItems();
        this.getProjectName();
        this.getReports(this.projects);
      },
      
    },
    created(){
      this.$store.dispatch('powerBI/getWorkspaces', this.currentUser.id);
      this.$store.dispatch('projects/retrieveWorkspaceData',  this.$route.params.projectid);
    },
    computed: {
      ...mapGetters('auth',[
        'currentUser'
      ]),
      ...mapGetters('powerBI',[
        'reports'
      ]),
      ...mapGetters('projects',[
        'projects',
        'users',
        'approvedReports'
      ]),
    },
    methods: {
      getProjectName(){
        this.projectRawData = this.projects;
        this.projectname = this.projectRawData[0].project_name;
      },
      getProjectType(){

      },
      getBreadcrumbItems(){
        this.projectRawData = this.projects;
        this.breadcrumbitems = [{
            text: 'Home',
            disabled: false,
            href: '/',
            id:'1',
          },
          {
            text: 'Projects',
            disabled: false,
            href: '/projects',
            id:'2',
          },
          {
            text: ''+this.projectRawData[0].project_name+'',
            disabled: true,
            href: '/projects/'+this.$route.params.projectid,
            id:'3',
          },
        ]
      },
      getReports(rawdata){
        let newArr = [];
        let newObj = {};
        let counter = 1;
        for (const [pj_obj_idx, pj_obj] of Object.entries(rawdata)) {
          newObj = {};
   
          newObj["action"] = 'GO TO REPORT';
          newObj["subtitle"] = 'Description: here'
          newObj["title"] =  pj_obj.description;
          newObj["href"] = ''+this.$route.params.projectid+'/reports/'+pj_obj.reportId+'';
          newObj["id"] = counter;
          newArr.push(newObj)
        }

        this.reportitems = newArr;
      },
  
   
      fileActionClick(item){
        if(item == 1){
          axios.get(endpoint + '/api/v1/files/pas',{	headers: authHeader(), responseType: 'blob'}).then((response) => {
          let blob = new Blob([response.data], {type:'application/*'})
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = "MASL2102Pb_PAS_Heterotic_pool_development.pdf";
          link.click();
          URL.revokeObjectURL(link.href);
          }).catch(error => {
          console.log(error);
        });
        }
        else if(item == 2 ){
          axios.get(endpoint + '/api/v1/files/file3',{	headers: authHeader(), responseType: 'blob'}).then((response) => {
          let blob = new Blob([response.data], {type:'application/*'})
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = "Heterotic_grouping_results.pdf";
          link.click();
          URL.revokeObjectURL(link.href);
          }).catch(error => {
          console.log(error);
          });
        }
        else if(item == 3){
          axios.get(endpoint + '/api/v1/files/file2',{	headers: authHeader(), responseType: 'blob'}).then((response) => {
          let blob = new Blob([response.data], {type:'application/*'})
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = "Heterotic_grouping_additional_results.pdf";
          link.click();
          URL.revokeObjectURL(link.href);
          }).catch(error => {
          console.log(error);
          });
        }else{
          axios.get(endpoint + '/api/v1/files/file1',{	headers: authHeader(), responseType: 'blob'}).then((response) => {
          console.log(response);
          let blob = new Blob([response.data], {type:'application/*'})
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = "Distance_matrix_all.csv";
          link.click();
          URL.revokeObjectURL(link.href);
          }).catch(error => {
          console.log(error);
          });
        }
      },
    },
  };
  </script>

<style lang="css" scoped>
  .project-breadcrumbs{
    padding-left:0px;
  }

  .v-card-title{
    padding:0px;
  }
  .files-assets-subcard{
    padding:15px;
  }

  .Reports-subcard{
    padding:15px;
  }
  .project-files-list{
    padding:0px;
  }

  .report-subtitle{
    margin-bottom:20px;
  }

</style>