import Vue from 'vue';
import VueRouter from 'vue-router';

import Overview from '../components/Overview.vue';
import NotFound from '../components/NotFound.vue';
import Dashboard from '../components/Dashboard.vue';
import Projects from '../components/Projects.vue';
import Project from '../components/Project.vue';
import Report from '../components/Report.vue';
import Admin from '../components/Admin.vue';
import Login from '../components/Login';
import Logout from '../components/Logout';

import store from '../store';
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: "home",
            component: Overview,
        },
        {
            path: '/projects',
            name: "projects",
            component: Projects,
        },
        {
            path: '/projects/:projectid',
            name: "project",
            component: Project,
        },
        {
            path: '/projects/:projectid/reports/:reportname',
            name: "report",
            component: Report,
        },
        {
            path: '/dashboard',
            name: "dashboard",
            component: Dashboard,
        },
        {
            path: '*',
            component: NotFound
        },
        {
            path: "/login",
            name: "login",
            component: Login,
        },
        {
            path: "/logout",
            name: "logout",
            component: Logout
        },
        {
            path: '/admin',
            name: "admin",
            component: Admin,
            beforeEnter: (to, from, next) => {
                const user = store.getters['auth/currentUser'];
                if(user.role != 'admin') {
                    next('/');
                }
                else {
                    next();
                }
            }
        },
    ]
    });

router.beforeEach((to, from, next) => {
    const publicPages = ['/login','/api'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = store.getters['auth/isAuthenticated'];

    if (authRequired && !loggedIn) {
      location.replace("/login");
      return false;
    } else {
      next();
    }
  });

export default router;