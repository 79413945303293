import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        light: true,
        themes: {
            light: {
                primary: '#492783',
                secondary: '#C9B5E8',
                accent: '#678916',
                error: '#b71c1c',
                grey: '#F7F7F7',
                greydark:'#696969'
            },
            dark: {

            },
          },
      },
});
