<template>
  <v-container fluid>
    <v-row dense class="component-headline">
      <strong><h3>Report - {{reportName}}</h3></strong>
    </v-row>
      <!-- breadcrumbs -->
      <v-row class="component-breadcrumbs">
        <v-breadcrumbs :items="breadcrumbItems">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        </v-breadcrumbs>
      </v-row>

<!-- <v-row dense class="component-headline"> -->
  <!-- {{ reportActive }} -->
  <v-row v-if="!reportActive" >
  <v-col cols="12">
  <v-card
    height="100%"
  >

    <v-card-title>Sorry, this report will be soon available!</v-card-title>
    <v-card-actions>
      <v-btn
      to="/"
        >
      return Home
    </v-btn>
    </v-card-actions>



  </v-card>
</v-col>
</v-row>

<v-row v-else >
  <v-col cols="12">
  <v-card
    height="100%"
  >
    <!-- <section id="reportContainer" class="embed-container"></section> -->
    <iframe title="MASL_Demo" :width="iframeWidth" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=beefbb06-3958-4e64-b1b4-67cb0f4a730b&autoAuth=true&ctid=a71f7b36-8f38-4fad-93d5-67dabaff9a46" frameborder="0" allowFullScreen="true"></iframe>

  </v-card>
</v-col>
</v-row>

  
  </v-container>
</template>


<script>
import { mapGetters } from 'vuex';
import * as pbi from 'powerbi-client';
import * as pbm from 'powerbi-models';

export default {
    name: 'Report',
    data() {
    return {
      reportLoading: false,
      reportActive: false,
      reportdata: [],
      powerBIResponse : '',
      iframeWidth: window.innerWidth-300,
      projectName: null,
      projectType: null,
      reportName: null,
      breadcrumbItems: [],
      report: null,
    };
    },
    mounted() {
      // console.log("mounted");
      this.initPowerBI();
      // console.log(this.tokenObject);
      // disabled due to a bug that allow inactive report as appoved 
      // if(this.tokenObject !== "") {
      //   console.log("Call render report func")
      //   this.renderReport();
      // }
    },
    created() {
      // console.log("Created")
      this.$store.dispatch('projects/retrieveReportData',  this.$route.params.reportname);
      //get workspace 
      this.$store.dispatch('powerBI/getWorkspaces', this.currentUser.id);
    },
    computed: {
      ...mapGetters('projects',[
        'projects',
        'users',
        'approvedReports'
      ]),
      ...mapGetters('powerBI',[
          'reports',
          'workspaces',
          'viewMode',
          'tokenObject',
          'activeElement',
          'saveState',
          'linkTable',
          'linkColumn'
      ]),
      ...mapGetters('settings',[
          'workspace',
        ]),
      ...mapGetters('auth',[
        'currentUser'
      ]),
    },
    watch: {
      projects() {
        // console.log("inside projects");
        //refactor now if data is empty report in console! and catch!
        if(Array.isArray(this.projects) && !this.projects.length){
          console.log("project is empty!")
        }else{
          this.getReportName(this.projects);
          this.getProjectName(this.projects);
          this.getBreadcrumbItems(this.projects);
        }
      },
      workspaces() {
        //retrieves all workspaces! Refactor later 
        // console.log("inside workspaces set active workspace ")
        // console.log(this.workspaces.length )
        // console.log(this.workspaces);
  
        let workspaceObj = {};
        for (const idx in this.workspaces){
          if (this.workspaces[idx].workspaceId ===  this.$route.params.projectid){
            workspaceObj["description"] = this.workspaces[idx].description 
            workspaceObj["id"] = this.workspaces[idx].id 
            workspaceObj["workspaceId"] =  this.$route.params.projectid
            workspaceObj["reportId"] = this.$route.params.reportname
            workspaceObj["projectName"] = this.workspaces[idx].description 
          }
        }
      // //  Currently we only support a singe workspace per user
      // if(this.workspaces.length == 1) {
      //   this.$store.dispatch('settings/setActiveWorkspace', this.workspaces[0]);
      // }else{ //just force to show the first workspace 
      //   this.$store.dispatch('settings/setActiveWorkspace', this.workspaces[0]);
      // }
      // gets the active workspace by user and workspace id from route

      //this.$route.params.projectid
      this.$store.dispatch('settings/setActiveWorkspace', workspaceObj);
    },
      reports(){
        //this is important func, gets active reports
        // console.log("retrieve active reports:")
        // console.log(this.reports)
        // console.log( this.workspace.reportId );
        // console.log(this.$route.params.reportname)
        let reportToRender=[];
        // check if current report page has approval!
        for (const idx in this.reports){
          if(this.reports[idx].reportId === this.workspace.reportId){
            // console.log("YAY Approved")
            // console.log(this.workspace.reportId+"==="+this.reports[idx].reportId )
            this.reportdata = this.reports;
            reportToRender.push(this.reports[idx])
       
          }else{
            console.log("Sorry, this report is not approved")
          }
        }

        // console.log(reportToRender)
        if(reportToRender.length != 0){
          //render powerBI dashboard 
          // console.log("Render now!")
          // console.log( this.workspace.workspaceId)
          // console.log(this.workspace.reportId)
          this.reportActive = true;
          this.$store.dispatch('powerBI/getReportToken', {workspaceId: this.workspace.workspaceId, reportId:  this.workspace.reportId});
          this.$store.dispatch('settings/setActiveReport', this.report);
        }else{
          //empty it!
          this.report = [];
        }
      },
      viewMode() {
        // console.log("inside viewMode")
      this.report.switchMode(this.viewMode);
      },
      tokenObject() {
        // console.log("inside tokenObject")
        this.renderReport();
        this.reportLoading = false;
       // this.$router.push({name: "dashboard"});
      },
      workspace() {
        // console.log("WORKSPACE() -> getReports in backend")
        // console.log(this.workspace.id) //database key pk id
        //get active reports
        this.$store.dispatch('powerBI/getReports', this.workspace.id);
     },
    },methods: {
      initPowerBI() {
        console.log("initPowerBi")
        this.container = document.getElementById('reportContainer');
        this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        this.powerbi.bootstrap(this.container, { type: "report"});
      },

      getReport(report) {
        // console.log("perfrom getReport() in report.vue")
        this.reportLoading = true;
        this.$store.dispatch('powerBI/getReportToken', {workspaceId: this.workspace.workspaceId, reportId: report.reportId});
        this.$store.dispatch('settings/setActiveReport', report);
      },

      renderReport() {
        // console.log("re-render in renderReport func");
        const user = this.$store.getters['auth/currentUser'];
        // Temp solution - refactor later
        this.container = document.getElementById('reportContainer');
        let models = pbm;
        // console.log(models);

        let loadConfig = {
          type: "report",
          tokenType: models.TokenType.Embed,
          accessToken: this.tokenObject.accessToken,
          embedUrl: this.tokenObject.embedUrl,
          pageView: 'fitToWidth',
          pageName: '',
          settings: {
            background: models.BackgroundType.Transparent,
            layoutType: models.LayoutType.Custom,
            customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
            },
            // filterPaneEnabled: false,  // filter options
            // navContentPaneEnabled: true, // bottom page select bar
            bars: {
              actionBar: {
              visible: true
              }
            },
            panes: {
              bookmarks: {
                  visible: false
              },
              fields: {
                  expanded: true
              },
              filters: {
                visible: false,
                expanded: true,
                
              },
              pageNavigation: {
                  visible: true,
                  position: models.PageNavigationPosition.Left,
              },
              selection: {
                  visible: true
              },
              syncSlicers: {
                  visible: true
              },
              visualizations: {
                  expanded: true
              }
            },
          },
          localeSettings: {
            language: "en",
            formatLocale: "en"
          }
        };

        let adminOptions = {};
        if(user.role == "admin") {
          adminOptions = {
            permissions: models.Permissions.All,  // Read, ReadWrite, Copy, Create, All
            viewMode: models.ViewMode.View,  // View or Edit
          }
        }

        loadConfig = Object.assign(loadConfig, adminOptions);

        this.report = this.powerbi.embed(this.container, loadConfig);

        this.report.off("loaded");

        // Report has finished loading script and data but has not rendered yet
        this.report.on("loaded", function() {
          // console.log("loaded report");           
          // If you want to hide to powerbi loading icon, you can use a overlay inside the iframe and hide it when its triggered here
          // Here we should add a function to refresh the token before it expires
        });

        this.report.off("rendered");

        // When the report has rendered, this will fire
        this.report.on("rendered", function() {
          // console.log("report rendered");
        });

        // hook to grab data
        var self = this;
        this.report.off("dataSelected");
        this.report.on("dataSelected", function(event) {
          try {
            var data = event.detail;
            var datapoint = data.dataPoints[0];


            // detect tables and columns
            if(self.saveState === "") {
              self.calibrateInteraction(datapoint);
            }
            else {
              for (var point in datapoint.identity) {
                // console.log(self.linkColumn);
                if(datapoint.identity[point].target.table == self.linkTable) {
                  if(datapoint.identity[point].target.column == self.linkColumn) {
                    let selected = datapoint.identity[point].equals;
                    console.log(selected);
                    self.$store.dispatch('powerBI/setActiveElement', selected);
                  }
                }
                // var str = JSON.stringify(datapoint.identity[point] , null, 4);
                // console.log( str )
              }
            }
          }
          catch(error) {
            console.log(error.message);
            self.$store.dispatch('powerBI/setActiveElement', null);
          }
        });

        this.report.off("error");
        this.report.on("error", function(event) {
        let msg = event.detail;
        console.error(`Error: ${msg}`);
        });


      },

      getReportName(rawData){
        this.reportName = rawData[0].description;
      },
      getProjectName(rawData){
        this.projectName = rawData[0].project_name;
      },
      getBreadcrumbItems(rawData){
        this.breadcrumbItems = [
        {
          text: 'Home',
          disabled: false,
          href: '/',
          id:'1',
        },
        {
          text: 'Projects',
          disabled: false,
          href: '/projects',
          id:'2',
        },
        {
          text: ''+rawData[0].project_name+'',
          disabled: false,
          href: '/projects/'+this.$route.params.projectid,
          id:'3',
        },
        {
          text: ''+rawData[0].description+'',
          disabled: true,
          href: '#',
          id:'4',
        },
        ];
      },
      goBack() {
        window.history.back();
      }

    }
  };
  </script>

<style lang="css" scoped>

  .component-headline{
    margin:0px;
    padding-top:0;
    padding-bottom:5px;
  }


  .component-breadcrumbs{
    margin:0px;
    padding-top:0;
    padding-bottom:5px;
  }

  .v-breadcrumbs{
    padding:0;
  }
  .embed-container{
    height:1000px;
  }

</style>