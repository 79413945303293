<template>
  <v-container fluid>
    <v-row dense class="component-headline">
    <strong><h3>Projects</h3></strong> 
    </v-row>

    <v-row class="component-breadcrumbs">
      <v-breadcrumbs :items="breadcrumbitems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
      </v-breadcrumbs>
    </v-row>

    <v-row dense>
    <v-col
    cols="12"
    >
    <v-card
        height="100%"
      >
        <v-card-title>My projects
          <v-spacer></v-spacer>
          <v-text-field
            class="my-search-field"
            color="primary"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="projectHeaders"
              :page.sync="page"
              :items="this.projectData"
              :search="search"
              :item-class="getRowItemClass"
              item-key="project_name"
              :items-per-page="itemsPerPage"
              show-expand
              hide-default-footer
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            >
              <template v-slot:top>
                <v-toolbar flat>
                <v-toolbar-title></v-toolbar-title>
                <v-spacer></v-spacer>
                </v-toolbar>
              </template>

              <template v-slot:item.project_name="{ item }">     
                <span class="primary--text font-weight-bold text-decoration-none">{{ item.project_name }}</span>
              </template>

              <template v-slot:item.ref_doc="{ item }">
                <a href="#" @click.prevent="downloadItemPas()" class="primary--text font-weight-bold text-decoration-none">{{ item.ref_doc.toUpperCase() }}
                <v-icon color="primary">
                    mdi-download
                </v-icon> </a>
              </template>

              <template v-slot:item.deliverables="{ item }">
                <span  class="primary--text font-weight-bold text-decoration-none">{{ item.deliverables }}</span>
              </template>

              <template v-slot:item.deliverables_url="{ item }">
                <v-btn
                text
                color="primary"
                class="primary--text font-weight-bold text-decoration-none" 
                :to="'projects/'+item.workspace_id" 
                >
                {{ item.deliverables_url }}
                </v-btn>
              </template>

                 <!--expand row-->
                <template v-slot:expanded-item="{ item }">
                  <td :colspan=7 >
                    <v-list>
                    <template v-for="(subitem, index)  in item.approved_reports">
                      <v-list-item :key="subitem.reportname" class="pa-0 report-expand-item">
                        <template>
                        <v-list-item-content>
                          <v-list-item-title class="text-subtitle-2 pa-1 ma-0 primary--text font-weight-bold text-decoration-none" v-text="subitem.report_name"></v-list-item-title>
                          <!-- <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle> -->
                          </v-list-item-content>
                          <v-btn
                          text
                          color="primary"
                          class="primary--text font-weight-bold text-decoration-none" 
                          :to="'projects/'+item.workspace_id+'/reports/'+subitem.report_id"
                          >
                          GO TO REPORT
                          </v-btn>
                        </template>
                      </v-list-item>

                      <v-divider
                      v-if="index < item.approved_reports.length - 1"
                      :key="index"
                      ></v-divider>
                    </template>

                    </v-list>
                  </td>
                </template>
                <!-- <template v-slot:expanded-item="{ item }">   
                <td>
                <p class="text-subtitle-2 pa-1 ma-0 primary--text font-weight-bold text-decoration-none"
                v-for="subitem in item.approved_reports"
                v-bind:key=subitem.id
                >
                {{ subitem.report_name }}
                </p >
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                <p class="text-subtitle-2 pa-1 ma-0" v-for="subitem in item.approved_reports"
                v-bind:key=subitem.id
                ><a class="primary--text font-weight-bold text-decoration-none" :href="'projects/'+item.workspace_id+'/reports/'+subitem.report_id">GO TO REPORT</a></p >
                </td>
                </template> -->

            </v-data-table>
          <v-card class="pa-3 v-card-project-suggestion"
            elevation="2"
          >
          <v-icon class="mb-2">mdi-lightbulb-on</v-icon>
          <span class="black--text font-weight-bold">    PROJECT SUGGESTION</span> - <span class="black--text font-weight-light">Anker Sorenson </span> "EDV-Breeding report is available!"</a>
          </v-card>

              <div class="d-flex ">
              <v-pagination
              class="project-pagination"
              v-model="page"
              :length="pageCount"
              ></v-pagination>

              </div>

          </v-card-text>

          <v-divider class="mx-4"></v-divider>
      </v-card>
    </v-col>
    </v-row>


  </v-container>

</template>

<script>

import { mapGetters } from 'vuex';

import axios from 'axios';
import endpoint from '../store/endpoint.js';
import authHeader from '../services/authHeader';


export default {
  name: 'Projects',
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 4,
      search: '',
      expanded: [],
      singleExpand: true,
      breadcrumbitems: [
        {
          text: 'Home',
          disabled: false,
          href: '/',
        },
        {
          text: 'projects',
          disabled: true,
          href: '/projects',
        },
      ],
      projectHeaders: [
        {
          text: 'PROJECT NAME',
          align: 'start',
          sortable: false,
          value: 'project_name',
        },
        { text: 'REF. DOCUMENT', value: 'ref_doc' },
        { text: 'PROJECT TYPE', value: 'project_type' },
        { text: 'STATUS', value: 'project_status' },
        { text: 'DELIVERABLES', value: 'deliverables' },
        { text: '', value: 'deliverables_url' },
        { text: '', value: 'data-table-expand' },
      ],
      projectData: [//to be filled in watch()
      ],
    }
  },
  mounted() {
    
  },
  watch: {
    projects() {
      this.projectRawData = this.projects;


      let newAr = [];
      var reportArr = [];
 
      var newObJ = {}
      for (const [pj_obj_idx, pj_obj] of Object.entries(this.projectRawData)) {
        var newObJ = {}
     

        //push report id and name to hasharray 
        if (reportArr[pj_obj.project_name]) { 
          reportArr[pj_obj.project_name].push({report_id: pj_obj.report_id, workspace_id: pj_obj.workspace_id, report_name:pj_obj.description})
        } else {
          reportArr[pj_obj.project_name] = [{report_id: pj_obj.report_id, workspace_id: pj_obj.workspace_id, report_name:pj_obj.description}]
        }

        // loop inside obj:
        for (const [key, value] of Object.entries(pj_obj)) {
          //console.log(key);
          //console.log(value);
          if (key === "project_name"){
            newObJ[key] = value
          }else if(key === "project_status"){
            newObJ[key] = value
          }else if(key === "project_type"){
            newObJ[key] = value
          }else if(key === "workspace_id"){
            newObJ[key] = value
          }
          newObJ["ref_doc"] = "pdf"
          newObJ["deliverables_url"] = "Open Project"
          newObJ["deliverables"] = "Available"
        }
        newAr.push(newObJ);
      
      }


      function getUniqueListBy(newAr, key) {
      return [...new Map(newAr.map(item => [item[key], item])).values()]
      }
      // main project structure is ready
      const arr1 = getUniqueListBy(newAr, 'workspace_id')
      this.projectData = arr1;
    
      // add reports data in the arr1 object
      for (const [pj_obj_idx, pj_obj] of Object.entries(arr1)) { 
            pj_obj["deliverables"] = reportArr[pj_obj.project_name].length
            pj_obj["approved_reports"] = reportArr[pj_obj.project_name]
      }
    },
  },
  created() {
    this.$store.dispatch('projects/retrieveAllProjects', this.currentUser.id);
  },
  components: {

  },
  computed: {
    ...mapGetters('auth',[
            'currentUser'
    ]),
    ...mapGetters('projects',[
      'projects',
      'users',
      'approvedReports'
    ]),
  },
  methods: {
    getRowItemClass: function (item) {
      return 'grey';
    },
    downloadItemPas(){
      console.log("download pas doc");
      axios.get(endpoint + '/api/v1/files/pas',{	headers: authHeader(), responseType: 'blob'}).then((response) => {

        console.log("response");

        console.log(response);
        let blob = new Blob([response.data], {type:'application/*'})
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = "MASL2102Pb_PAS_Heterotic_pool_development.pdf";
        link.click();
        URL.revokeObjectURL(link.href);
        }).catch(error => {
        console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.reportContainer{
  width: 100%;
  height: 100%;
  border: none;
}

.component-headline{
  margin:0px;
  padding-top:0;
  padding-bottom:5px;
}

.component-breadcrumbs{
  margin:0px;
  padding-top:0;
  padding-bottom:5px;
}

.v-breadcrumbs{
  padding:0;
}

.my-search-field{
  background-color: #F7F7F7;
}

.report-expand-item{
  margin-left:20px;
}

.go-to-report-link{
  margin-right:60px;
}

/* clean shadow in expand row */
.v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}


</style>