<template>
        <v-bottom-sheet
      hide-overlay
      v-model="sheet"
    >
          <v-sheet
        class="text-center pl-8"
        height="200px"
      >
      <p class="text-center">
      Configure out-link values
        </p>
      <v-row>
 
        <v-col>
            <v-select
          :items="linkData.tables"
          label="select table"
          v-model="table"
        ></v-select>
        <v-spacer></v-spacer>

        <v-select
          :items="linkData.columns"
          label="select column"
          v-model="column"
        ></v-select>
        </v-col>
        <v-col>

<v-text-field
      label="outlink"
      hide-details="auto"
      prepend-icon="mdi-link"
      append-icon="mdi-link-plus"
      @click:append="addOutLink"
      hint="example: http://croppedia.com/browser/{item}"
    ></v-text-field>


        </v-col>
        <v-col>

          <v-btn
            color="default"
            @click="sheet = !sheet"
            small
          >
            close
          </v-btn>
           
           <v-btn
            color="primary"
            @click="submit()"
            small
          >
            save
          </v-btn>

        </v-col>
      </v-row>
      </v-sheet>


          <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      color="primary"
      outlined
    >
      {{ snacktext }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
        
    </v-bottom-sheet>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  data: () => ({
      sheet: false,
      table: null,
      column: null,
      linkData: {
          tables: null,
          columns: null
      },
      snackbar: false,
      snacktext: '',
      timeout: 2000
    }),
  components: {
  },
  computed: {
    ...mapGetters('auth',[
            'isAuthenticated',
            'currentUser'
        ]),
    ...mapGetters('powerBI',[
            'linkOptions',
        ]),
  },
  mounted() {

  },
  watch: {
      linkOptions() {
          this.sheet = true;
          this.linkData = this.linkOptions; 
      }
  },
  methods: {
    submit() {
      if( (this.table !== null) && (this.column !== null) ) {
        console.log("ToDo");
      }
    },
    addOutLink() {
      this.snackbar = true;
      this.snacktext = "outlink is configured."
    }
  }
};
</script>
