import axios from 'axios';
import authHeader from '../../../services/authHeader';

import endpoint from '../../endpoint.js';

const state = {
	approvedReports: {},
	projects: {},
	users: {},
  navLinks: {},
}

const mutations = {
	UPDATE_PROJECTS (state, payload) {
    state.projects = payload;
  },
  UPDATE_APPROVEDREPORTS (state, payload) {
    state.approvedReports = payload;
  },
  UPDATE_NAVLINKS (state, payload) {
    state.navLinks = payload;
  }
}

const actions = {
  retrieveAllProjects ( context , user_id ) {
    axios.get(endpoint + '/api/v1/projects/reports/users/'+user_id+'' ,{
      headers: authHeader() 
    }).then((response) => {
      context.commit('UPDATE_PROJECTS', response.data)
    });
  },
  retrieveApprovedReports ( context , user_id ) {
    //axios.get(endpoint + '/api/v1/projects/reports/users/'+user_id+'' ,{
      axios.get(endpoint + '/api/v1/projects/reports/users/1' ,{
      headers: authHeader() 
    }).then((response) => {

      context.commit('UPDATE_APPROVEDREPORTS', response.data)
    });
  },
  // project page
  retrieveWorkspaceData ( context , workspace_id ) {
    axios.get(endpoint + '/api/v1/workspace/'+workspace_id+'' ,{
      headers: authHeader() 
    }).then((response) => {

      context.commit('UPDATE_PROJECTS', response.data)
    });
  },
  //report page
  retrieveReportData ( context , report_id ) {
    axios.get(endpoint + '/api/v1/reports/'+report_id+'' ,{
      headers: authHeader() 
    }).then((response) => {

      context.commit('UPDATE_PROJECTS', response.data)
    });
  },
  //nav links
  retrieveAllProjectsForNavLinks ( context , user_id ) {
    axios.get(endpoint + '/api/v1/projects/reports/users/'+user_id+'' ,{
      headers: authHeader() 
    }).then((response) => {
  
      context.commit('UPDATE_NAVLINKS', response.data)
    });
  },
  
}
const getters = {
	approvedReports: state => state.approvedReports,
	projects: state => state.projects,
	users: state => state.users,
  navLinks: state=> state.navLinks
};

const settingsModule = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}

export default settingsModule;
