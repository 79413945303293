<template>
<v-container>
    <v-row align="center"
      justify="center">
        <v-col>

  <v-card
  elevation="2"
   outlined
   width="500"
>

<v-img
      height="250"
      src="/keygenebuilding.png"
    ></v-img>

    <v-card-text>

    <v-form @submit.prevent="login" v-model="valid">

    <h1 class="text-center">
      log in
    </h1>
      <v-text-field
      label="username"
      v-model="username"
      :rules="[rules.required, rules.max]"
      hide-details="auto"
    ></v-text-field>

    <v-text-field
      v-model="password"
      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="[rules.required, rules.min]"
      :type="show1 ? 'text' : 'password'"
      name="input-10-1"
      label="password"
      hint="At least 4 characters"
      counter
      @click:append="show1 = !show1"
          ></v-text-field>

      <v-btn type="submit" color="primary" :disabled="!valid" >Login</v-btn>
    </v-form>

    </v-card-text>

        <v-card-text v-if="errorMsg">

       <v-alert 
      border="right"
      colored-border
      type="error"
      elevation="2"
    >
    error: <strong>{{errorMsg}}</strong>. Please log in again.
    </v-alert>

    </v-card-text>

</v-card>

        </v-col>


    </v-row>

</v-container>
</template>

<style>
.login {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 10px;
}
</style>

<script>

export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      errorMsg: null,
      show1: false,
      valid: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 4 || 'Min 4 characters',
        max: v => v.length <= 10 || 'Max 10 characters',
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters['auth/isAuthenticated'];
    },
  },
  created() {
    if(this.$route.params.error) {
      this.errorMsg = this.$route.params.error;
    }
    if (this.loggedIn) {
      this.$router.push({name: "home"});
    }
  },
  methods: {
    login: function() {
      this.$store.dispatch('auth/retrieveToken', { 
        username: this.username,
        password: this.password
        })
        .then(response => {
          if(response.status == 200) {
            try {
              if(response.data.role == "admin") {
                this.$router.push({name: "admin"});
              }
              else {
                this.$router.push({name: "home"}); 
              }
              location.reload() 
            }
            catch(error) {
              this.$router.push({name: "home"});
            }
            //   });
          }else{
            // console.log(response.status)
            this.errorMsg = response.data;
          }
      });
    }
  }
};
</script>