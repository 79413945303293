<template>
<div class="has-text-centered">
    <h1 class="title">Sorry. Page Not Found :(</h1>
    <p>this is an 404 page.</p>
</div>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>

<style scoped>

</style>