import axios from 'axios';
import authHeader from '../../../services/authHeader';

import endpoint from '../../endpoint.js';

const state = {
	approvals: {},
	reports: {},
	users: {}
}

const mutations = {
    UPDATE_APPROVAL (state, payload) {
        state.approvals = payload;
	},
	UPDATE_REPORTS (state, payload) {
        state.reports = payload;
	},
	UPDATE_USERS (state, payload) {
        state.users = payload;
	},
}

const actions = {
	retrieveAllReports ( context ) {
    //console.log("triggering retrieveAllReports()")
		axios.get(endpoint + '/api/v1/admin/reports' ,{
			headers: authHeader() 
		}).then((response) => {
		  context.commit('UPDATE_REPORTS', response.data)
		});
	},
	retrieveApprovalTable ( context , report_id ) {
    //console.log("retrieveApprovalTable triggered")
		axios.get(endpoint + '/api/v1/admin/approve/'+report_id+'' ,{
			headers: authHeader() 
		}).then((response) => {
		context.commit('UPDATE_APPROVAL', response.data)
		}).catch(error => {
			console.log(error.response);
      // console.log(error);
		});
	},
	approveReport ( context , data ) {
    //console.log("approveReport triggered")
		axios.put(endpoint + '/api/v1/admin/approve/'+data.report_id,{user_id: data.user_id} ,{
			headers: authHeader() 
		}).then((response) => {
			context.commit('UPDATE_APPROVAL', response.data)
		});
	},
	addOutLink ( context , data ) {
		axios.put(endpoint + '/api/v1/admin/report/'+data.report_id +'/outlink',
		{
			table: data.table,
			column: data.column
		},{
			headers: authHeader() 
		}).then((response) => {
			//context.commit('UPDATE_OUTLINK', response.data)
			console.log('success  addoutlink');
      // console.log(response.data);
		});
	}
}

const getters = {
	approvalTable: state => state.approvals,
	reports: state => state.reports,
	users: state => state.users
};

const settingsModule = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
export default settingsModule;