import Vue from 'vue';
import Vuex from 'vuex';

import powerBI from './modules/powerBI';
import auth from './modules/Auth';
import settings from './modules/Settings';
import admin from './modules/Admin';
import projects from './modules/Projects';

Vue.use(Vuex);

export default new Vuex.Store({
modules: {
	auth,
	powerBI,
	settings,
	admin,
  projects,
	}
});